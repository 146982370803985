
// Libraries
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCheckCircle, faRedo, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { faFolderUpload } from '@fortawesome/pro-solid-svg-icons'
import Dropzone from 'react-dropzone'
import Classnames from 'classnames'
import {Autocomplete, useLoadScript} from '@react-google-maps/api'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import ReactSelectCreatable from 'react-select/creatable'
import ReactSelectAsyncCreatable from 'react-select/async-creatable'
import { Editor } from '@tinymce/tinymce-react'

// Services
import BroadcastsService from '../services/broadcasts'

// Components
import Button from './button'

// Images
import placeholderImgSrc from '../img/profile/placeholder.png'

export default function InputFactory(props) {
	const {type, error, editToggle, labelEndeavour, large, checkboxEndeavour} = props

	const [editing, setEditing] = useState(null),
		[filePreview, setFilePreview] = useState(false),
		[initialFilePreviewPassed, setInitialFilePreviewPassed] = useState(false),
		[autoComplete, setAutocomplete] = useState(null),
		[addressFound, setAddressFound] = useState(null)

	let classes = Classnames([
		'input-wrap',
		`input-wrap input-wrap--${type}`,
		{
			'input-wrap--has-error': error && error !== '' && type !== 'address',
			'input-wrap--edit-toggle': editToggle,
			'input-wrap--label-endeavour': labelEndeavour,
			'input-wrap--edit-toggle-active': editToggle && editing,
			'input-wrap--large': large,
			'input-wrap--checkbox-endeavour': checkboxEndeavour,
		},
	])

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GOOGLE_PLACES_KEY,
		libraries: ['places'],
	})

	function renderInput() {
		const {type} = props
		const {error, ...inputProps} = props

		if (type === 'checkbox') {
			const {name} = inputProps
			const {label, placeholder, onChange, checkboxEndeavour, ...checkboxProps} = inputProps

			return <>
				<input id={`field-${name}`} onChange={handleCheckboxChange} {...checkboxProps} />
				<label htmlFor={`field-${name}`}>{label}</label>
			</>
		}
		else if (type === 'checkbox-group') {
			const {name, label, options} = inputProps

			return <fieldset>
				<legend>{label}</legend>
				{
					options.map((option) => <div className='input-group_input'>
						<input name={`field-${name}`} id={`field-${name}-${option.id}`} value={option.id} type='checkbox' onChange={handleCheckboxGroupChange} />
						<label htmlFor={`field-${name}-${option.id}`}>{option.text}</label>
					</div>)
				}
			</fieldset>
		}
		else if (type === 'radio-group') {
			const {name, label, options} = inputProps

			return <fieldset>
				<legend>{label}</legend>
				{
					options.map((option) => <div className='input-group_input'>
						<input name={`field-${name}`} id={`field-${name}-${option.id}`} value={option.id} type='radio' onChange={handleInputChange} />
						<label htmlFor={`field-${name}-${option.id}`}>{option.text}</label>
					</div>)
				}
			</fieldset>
		}
		else if (type === 'textarea') {
			const {name} = inputProps
			const {label, onChange, ...fieldProps} = inputProps

			// To-Do: Update textareas so we don't have to disable Grammarly
			// Grammarly was causing previously hidden textareas to break

			return <>
				{label && <label htmlFor={`field-${name}`}>{label}</label>}
				<textarea id={`field-${name}`} onChange={handleInputChange} {...fieldProps} data-gramm_editor="false" />
			</>
		}
		else if (type === 'select') {
			const {label, name, placeholder, onChange, requireSearch, requireSearchMinLength, large, loadOptions, ...selectProps} = inputProps
			const styles = {
				control: (provided) => ({
					...provided,
					borderRadius: '32px',
				}),
				valueContainer: (provided) => ({
					...provided,
					padding: !large ? '5px 20px 8px' : '12px 14px',
				}),
				singleValue: (provided) => ({
					...provided,
					color: '#005EB8',
				})
			}

			let filterOption

			if(requireSearch) {
				const controlMinSearchLength = requireSearchMinLength || 3;
				filterOption = (option, search) => {
					const optionMinSearchLength = Math.min(option.label.trim().length, controlMinSearchLength);
					if (!search || search.length < optionMinSearchLength) {
						return false
					}

					return option.label.trim().toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
				}
			}

			return <>
				{label && <label htmlFor={`field-${name}`}>{label}</label>}
				{requireSearch
					? <ReactSelect styles={styles} filterOption={filterOption} onChange={handleSelectChange} name={name} id={`field-${name}`} placeholder={placeholder} {...selectProps} />
					: loadOptions
						? <AsyncSelect styles={styles} onChange={handleSelectChange} name={name} id={`field-${name}`} loadOptions={loadOptions} placeholder={placeholder} {...selectProps} />
						: <ReactSelect styles={styles} onChange={handleSelectChange} name={name} id={`field-${name}`} placeholder={placeholder} {...selectProps} />
				}
			</>
		}
		else if (type === 'select-tags') {
			const {label, name, placeholder, onChange, requireSearch, requireSearchMinLength, large, loadOptions, ...selectProps} = inputProps
			const styles = {
				control: (provided) => ({
					...provided,
					borderRadius: '32px',
				}),
				valueContainer: (provided) => ({
					...provided,
					padding: !large ? '5px 20px 8px' : '12px 14px',
				}),
				singleValue: (provided) => ({
					...provided,
					color: '#005EB8',
				})
			}

			let filterOption

			if(requireSearch) {
				const controlMinSearchLength = requireSearchMinLength || 3;
				filterOption = (option, search) => {
					const optionMinSearchLength = Math.min(option.label.trim().length, controlMinSearchLength);
					if (!search || search.length < optionMinSearchLength) {
						return false
					}

					return option.label.trim().toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
				}
			}

			return <>
				{label && <label htmlFor={`field-${name}`}>{label}</label>}
				{requireSearch
					? <ReactSelectCreatable styles={styles} filterOption={filterOption} onChange={handleSelectChange} name={name} id={`field-${name}`} {...selectProps} />
					: loadOptions
						? <ReactSelectAsyncCreatable styles={styles} onChange={handleSelectChange} name={name} id={`field-${name}`} loadOptions={loadOptions} {...selectProps} />
						: <ReactSelectCreatable styles={styles} onChange={handleSelectChange} name={name} id={`field-${name}`} {...selectProps} />
				}
			</>
		}
		else if (type === 'file') {
			const {name} = inputProps
			const {label, initialFilePreview, profile, onDrop, ...fieldProps} = inputProps

			if (!profile && initialFilePreview && !initialFilePreviewPassed) {
				setFilePreview(initialFilePreview)
				setInitialFilePreviewPassed(true)
			}

			return <Dropzone multiple={false} maxFiles={1} onDrop={handleFileDrop}>
				{({getRootProps, getInputProps, acceptedFiles}) => (
					<div>
						{label && <label htmlFor={`field-${name}`}>{label}</label>}

						{/* To-do: clean this up/refactor */}

						{!profile ?
							<div className="dropzone" {...getRootProps()}>
								{(!filePreview)
									? <>
										<FontAwesomeIcon icon={faFolderUpload}/>
										<p>Drag your files here or</p>
										<Button type="button">Browse</Button>
										<input id={`field-${name}`} {...fieldProps} {...getInputProps()} />
									</>
									: <div className="preview">
										<img src={filePreview} alt="Your uploaded file"/>
										{(!profile && !editing) &&
											<button className="clearImage" type="button" onClick={(event) => {
												event.stopPropagation();
												setFilePreview(false);
											}}>
												<FontAwesomeIcon icon={faTimesCircle}/></button>}
									</div>
								}
							</div>
							:
							<div className="dropzone" {...getRootProps()}>
								{initialFilePreview && !filePreview && !editing &&
									<img src={initialFilePreview} alt="Your uploaded file"/>
								}

								{!editing && !initialFilePreview && !filePreview &&
									<img src={placeholderImgSrc} alt="Profile placeholder" className="placeholder" />
								}

								{!editing && filePreview && <img src={filePreview} alt="Your uploaded file"/>}

								{editing &&
									<>
										{!filePreview ?
											<>
												<FontAwesomeIcon icon={faFolderUpload}/>
												<p>Drag your files here or</p>
												<Button type="button">Browse</Button>
												<input id={`field-${name}`} {...fieldProps} {...getInputProps()} />
											</>
											:
											acceptedFiles.map((file) =>
												<div>
													<img src={filePreview} alt="Your uploaded file"/>
													<button type="button" className="clearImage" onClick={(event) => {
														event.stopPropagation();
														setFilePreview(false);
													}}>
														<FontAwesomeIcon icon={faTimesCircle}/></button>
												</div>
											)
										}
									</>
								}
							</div>
						}
					</div>
				)}
			</Dropzone>
		}
		else if (type === 'address') {
			const {name, label, tooltip, value, onChange, type, editToggle, ...fieldProps} = inputProps

			const regionStyles = {
				control: (provided) => ({
					...provided,
					borderRadius: '32px',
				}),
				valueContainer: (provided) => ({
					...provided,
					padding: !large ? '5px 20px 8px' : '12px 14px',
				}),
				singleValue: (provided) => ({
					...provided,
					color: '#005EB8',
				})
			}

			const regionOptions = [
				{label: 'Channel Islands', value: 'Channel Islands'},
				{label: 'East Midlands', value: 'East Midlands'},
				{label: 'East of England', value: 'East of England'},
				{label: 'Greater London', value: 'Greater London'},
				{label: 'Isle of Man', value: 'Isle of Man'},
				{label: 'North East', value: 'North East'},
				{label: 'North West', value: 'North West'},
				{label: 'Northern Ireland', value: 'Northern Ireland'},
				{label: 'Scotland: North', value: 'Scotland: North'},
				{label: 'Scotland: South', value: 'Scotland: South'},
				{label: 'South East', value: 'South East'},
				{label: 'South West', value: 'South West'},
				{label: 'Wales: North', value: 'Wales: North'},
				{label: 'Wales: South', value: 'Wales: South'},
				{label: 'West Midlands', value: 'West Midlands'}
			]

			const regionValue = value?.region
				? {
					label: value?.region,
					value: value?.region
				}
				: null

			return <>
				{(tooltip && label) && <label>{label} {tooltip}</label>}
				{(!tooltip && label) && <label htmlFor={`field-${name}`}>{label}</label>}

				{isLoaded &&
					<div className="address-autocomplete">
						<Autocomplete types={['address']} fields={['address_components', 'geometry.location']} restrictions={{country: 'UK'}} onLoad={handleAutocompleteLoaded} onPlaceChanged={handlePlaceChanged}>
							<input autoComplete="off" type="search" id={`field-${name}`} {...fieldProps} placeholder="Find your address here..." />
						</Autocomplete>

						{(!editToggle || (editing || value?.street)) && <input type="text" autoComplete="off" value={value?.street} onChange={(e) => {onChange({
							...value,
							street: e.target.value
						}, name)}} placeholder="Street*" className={error?.street && 'has-error'} />}
						{error?.street && createErrorElement(error.street)}

						{(!editToggle || editing || value?.street_2) && <input type="text" autoComplete="off" value={value?.street_2} onChange={(e) => {onChange({
							...value,
							street_2: e.target.value
						}, name)}} placeholder="Street 2" className={error?.street_2 && 'has-error'} />}
						{error?.street_2 && createErrorElement(error.street_2)}

						{(!editToggle || editing || value?.city) && <input type="text" autoComplete="off" value={value?.city} onChange={(e) => {onChange({
							...value,
							city: e.target.value
						}, name)}} placeholder="Town/City*" className={error?.city && 'has-error'} />}
						{error?.city && createErrorElement(error.city)}

						{(!editToggle || editing || value?.state) && <input type="text" autoComplete="off" value={value?.state} onChange={(e) => {onChange({
							...value,
							state: e.target.value
						}, name)}} placeholder="County*" className={error?.state && 'has-error'} />}
						{error?.state && createErrorElement(error.state)}

						{(!editToggle || editing || value?.postal_code) && <input type="text" autoComplete="off" value={value?.postal_code} onChange={(e) => {onChange({
							...value,
							postal_code: e.target.value
						}, name)}} placeholder="Postal Code*" className={error?.postal_code && 'has-error'} />}
						{error?.postal_code && createErrorElement(error.postal_code)}

						{(!editToggle || editing || value?.region) && <div className={`region-wrap input-wrap input-wrap--select ${error?.region && 'has-error'}`}><ReactSelect
							className={`select-field`}
							styles={regionStyles}
							onChange={(option) => {onChange({
								...value,
								region: option.value
							}, name)}}
							options={regionOptions}
							value={regionValue}
							placeholder='Region*' /></div>}
						{error?.region && createErrorElement(error.region)}
					</div>
				}
			</>
		}
		else if (type === 'tinymce') {
			const {label, name, config, initialValue, relativeUrls, removeScriptHost, convertUrls} = inputProps

			let tinymceInit = {}
			if (config === 'page') {
				tinymceInit = {
					height: 500,
					menubar: false,
					plugins: [
						'advlist autolink lists link searchreplace fullscreen paste code'
					],
					toolbar: 'undo redo | styleselect bold bullist numlist link fullscreen code',
					content_style: 'body {' +
							'font-family: acumin-pro,sans-serif,' +
							'font-size:18px ' +
						'}' +
							'h2, h3, small {' +
							'color: #005EB8;' +
						'}' +
						'h2, h3 {' +
							'font-family: acumin-pro-wide,sans-serif;' +
							'font-style: italic;' +
							'font-weight: 900;' +
							'text-transform: uppercase;' +
							'}' +
						'h2 {' +
							'font-size: 26px;' +
						'}' +
						'h3 {' +
							'font-size: 22px;' +
							'}' +
						'small {' +
							'font-size: 16px;' +
						'}' +
						'ul li::marker, ol li::marker {' +
							'color: #005EB8;' +
						'}' +
						'a {' +
							'color: #065db8' +
						'}' +
						'a.button {' +
							'font-size: 18px;' +
							'color: #005EB8; ' +
							'font-weight: bold; ' +
							'font-style: italic;' +
							'text-transform: uppercase;' +
							'text-decoration: none; ' +
							'border-radius: 40px; ' +
							'background-color: #4CE0FA; ' +
							'border-top: 12px solid #4CE0FA; ' +
							'border-bottom: 12px solid #4CE0FA; ' +
							'border-right: 18px solid #4CE0FA; ' +
							'border-left: 18px solid #4CE0FA; ' +
						'}' +
						'img {' +
							'max-width: 100%;' +
							'height: auto;' +
						'}',
					formats: {
						p: { block: "p" },
						h2: { block: "h2" },
						h3: { block: "h3" },
						h4: { block: "h4" },
						small: { block: "small" },
					},
					style_formats: [
						{ title: "H2", format: "h2" },
						{ title: "H3", format: "h3" },
						{ title: "Paragraph", format: "p" },
						{ title: "Small", format: "small" },
					],
					link_class_list: [
						{title: 'None', value: ''},
						{title: 'Button', value: 'button'},
					],
					link_title: true,
					target_list: true,
					relative_urls: false,
					remove_script_host : true,
				}
			}
			else if (config === 'blog') {
				tinymceInit = {
					height: 500,
					menubar: false,
					plugins: [
						'advlist autolink lists link image media searchreplace fullscreen paste code'
					],
					toolbar: 'undo redo | styleselect bold bullist numlist link image fullscreen code',
					images_upload_handler: handleTinymceImageUpload,
					content_style: 'body {' +
							'font-family: acumin-pro,sans-serif,' +
							'font-size: 18x ' +
						'}' +
						'h2, h3, small {' +
							'color: #005EB8;' +
						'}' +
						'h2, h3 {' +
							'font-family: acumin-pro-wide,sans-serif;' +
							'font-style: italic;' +
							'font-weight: 900;' +
							'text-transform: uppercase;' +
						'}' +
						'h2 {' +
							'font-size: 26px;' +
						'}' +
						'h3 {' +
							'font-size: 22px;' +
						'}' +
						'small {' +
							'font-size: 16px;' +
						'}' +
						'ul li::marker, ol li::marker {' +
							'color: #005EB8;' +
						'}' +
						'a {' +
							'color: #065db8' +
						'}' +
						'a.button {' +
							'font-size: 18px;' +
							'color: #005EB8; ' +
							'font-weight: bold; ' +
							'font-style: italic;' +
							'text-transform: uppercase;' +
							'text-decoration: none; ' +
							'border-radius: 40px; ' +
							'background-color: #4CE0FA; ' +
							'border-top: 12px solid #4CE0FA; ' +
							'border-bottom: 12px solid #4CE0FA; ' +
							'border-right: 18px solid #4CE0FA; ' +
							'border-left: 18px solid #4CE0FA; ' +
						'}' +
						'img {' +
							'max-width: 100%;' +
							'height: auto;' +
						'}',
					formats: {
						p: { block: "p" },
						h2: { block: "h2" },
						h3: { block: "h3" },
						h4: { block: "h4" },
						small: { block: "small" },
					},
					style_formats: [
						{ title: "H2", format: "h2" },
						{ title: "H3", format: "h3" },
						{ title: "Paragraph", format: "p" },
						{ title: "Small", format: "small" },
					],
					link_class_list: [
						{title: 'None', value: ''},
						{title: 'Button', value: 'button'},
					],
					link_title: true,
					target_list: true,
					relative_urls: false,
					remove_script_host : true,
				}
			}
			else if (config === 'ballot-winners') {
				tinymceInit = {
					height: 250,
					menubar: false,
					plugins: [
						'advlist autolink lists link image media searchreplace fullscreen paste code'
					],
					toolbar: 'undo redo | styleselect bold bullist numlist link image fullscreen code',
					images_upload_handler: handleTinymceImageUpload,
					content_style: 'body {' +
							'font-family: acumin-pro,sans-serif,' +
							'font-size: 18x ' +
						'}' +
						'h2, h3, small {' +
							'color: #005EB8;' +
						'}' +
						'h2, h3 {' +
							'font-family: acumin-pro-wide,sans-serif;' +
							'font-style: italic;' +
							'font-weight: 900;' +
							'text-transform: uppercase;' +
						'}' +
						'h2 {' +
							'font-size: 26px;' +
						'}' +
						'h3 {' +
							'font-size: 22px;' +
						'}' +
						'small {' +
							'font-size: 16px;' +
						'}' +
						'ul li::marker, ol li::marker {' +
							'color: #005EB8;' +
						'}' +
						'a {' +
							'color: #065db8' +
						'}' +
						'a.button {' +
							'font-size: 18px;' +
							'color: #005EB8; ' +
							'font-weight: bold; ' +
							'font-style: italic;' +
							'text-transform: uppercase;' +
							'text-decoration: none; ' +
							'border-radius: 40px; ' +
							'background-color: #4CE0FA; ' +
							'border-top: 12px solid #4CE0FA; ' +
							'border-bottom: 12px solid #4CE0FA; ' +
							'border-right: 18px solid #4CE0FA; ' +
							'border-left: 18px solid #4CE0FA; ' +
						'}' +
						'img {' +
							'max-width: 100%;' +
							'height: auto;' +
						'}',
					formats: {
						p: { block: "p" },
						h2: { block: "h2" },
						h3: { block: "h3" },
						h4: { block: "h4" },
						small: { block: "small" },
					},
					style_formats: [
						{ title: "H2", format: "h2" },
						{ title: "H3", format: "h3" },
						{ title: "Paragraph", format: "p" },
						{ title: "Small", format: "small" },
					],
					link_class_list: [
						{title: 'None', value: ''},
						{title: 'Button', value: 'button'},
					],
					link_title: true,
					target_list: true,
					relative_urls: false,
					remove_script_host : true,
				}
			}
			else {
				tinymceInit = {
					height: 500,
					menubar: false,
					plugins: [
						'advlist autolink lists link image media searchreplace fullscreen paste code'
					],
					toolbar: 'undo redo | styleselect bold bullist numlist link image fullscreen code',
					images_upload_handler: handleTinymceImageUpload,
					content_style: 'body {' +
							'font-family:-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Helvetica, Arial, sans-serif,' +
							'\'Apple Color Emoji\', \'Segoe UI Emoji\', \'Segoe UI Symbol\'; ' +
							'font-size:14px ' +
						'}' +
						'a {' +
							'color: #065db8' +
						'}' +
						'a.button {' +
							'display: inline-block;' +
							'font-size: 16px;' +
							'color: #FFF; ' +
							'font-weight: bold; ' +
							'font-style: italic;' +
							'text-transform: uppercase;' +
							'text-decoration: none; ' +
							'border-radius: 40px; ' +
							'background-color: #065db8; ' +
							'margin: 20px 15px 25px 0; ' +
							'border-top: 12px solid #065db8; ' +
							'border-left: 22px solid #065db8; ' +
							'border-right: 22px solid #065db8; ' +
							'border-bottom: 12px solid #065db8; ' +
						'}' +
						'img {' +
							'max-width: 100%;' +
							'height: auto;' +
						'}',
					formats: {
						p: { block: "p", styles: { fontSize: "14px" } },
						h1: { block: "h1", styles: { fontSize: "18px", color: "#065db8" } },
						h2: { block: "h2", styles: { fontSize: "16px" } },
						h3: { block: "h3", styles: { fontSize: "14px" } },
						small: { block: "small", styles: { fontSize: "12px" } },
					},
					style_formats: [
						{ title: "H1", format: "h1" },
						{ title: "H2", format: "h2" },
						{ title: "H3", format: "h3" },
						{ title: "Paragraph", format: "p" },
						{ title: "Small", format: "small" },
					],
					link_class_list: [
						{title: 'None', value: ''},
						{title: 'Button', value: 'button'},
					],
					link_title: false,
					target_list: false,
					relative_urls: relativeUrls || false,
					remove_script_host: removeScriptHost || false,
					convert_urls: convertUrls !== false
				}
			}

			return <div>
				{label && <label htmlFor={`field-${name}`}>{label}</label>}
				<Editor
					apiKey={process.env.GATSBY_TINY_CLOUD_KEY}
					init={tinymceInit}
					initialValue={initialValue}
					onEditorChange={(editorValue) => handleTinymceChange(editorValue)}
				/>
			</div>
		}
		else {
			const {name} = inputProps
			const {label, onChange, ...fieldProps} = inputProps

			return <>
				{label && <label htmlFor={`field-${name}`}>{label}</label>}
				<input id={`field-${name}`} onChange={handleInputChange} {...fieldProps} />
			</>
		}
	}

	function handleInputChange(changeEvent) {
		const {onChange, name, scope} = props
		onChange(changeEvent.target.value, name, scope)
	}

	function handleCheckboxChange(changeEvent) {
		const {onChange, name, scope} = props
		onChange(changeEvent.target.checked, name, scope)
	}

	function handleCheckboxGroupChange(changeEvent) {
		const {onChange, name, scope, noneAnswerId} = props
		let values = []

		if (noneAnswerId && changeEvent.target.checked && changeEvent.target.value === noneAnswerId.toString()) {
			changeEvent.target.closest('.input-wrap--checkbox-group')
				.querySelectorAll('input:not(#' + changeEvent.target.id + '):checked')
				.forEach(function(input) {
					input.checked = false
				});
		}
		else if (noneAnswerId && changeEvent.target.checked) {
			changeEvent.target.closest('.input-wrap--checkbox-group')
				.querySelectorAll('input[value="' + noneAnswerId + '"]:checked')
				.forEach(function(input) {
					input.checked = false
				});
		}

		changeEvent.target.closest('.input-wrap--checkbox-group')
			.querySelectorAll('input:checked')
			.forEach(function(input) {
				values.push(input.value)
			});

		if (noneAnswerId && values.includes(noneAnswerId.toString())) {
			// set value to noneAnswerId only
			values = [noneAnswerId.toString()]

			// uncheck all other options
			changeEvent.target.closest('.input-wrap--checkbox-group')
				.querySelectorAll('input:not([value="' + noneAnswerId + '"]):checked')
				.forEach(function(input) {
					input.checked = false
				});
		}
		else if (values.length === 0) {
			values = undefined
		}

		onChange(values, name, scope)
	}

	function handleSelectChange(option) {
		const {onChange, name, scope} = props
		onChange(option, name, scope)
	}

	function handleFileDrop(files) {
		const {onChange, name, scope} = props,
			file = files[0]

		let filePreview = URL.createObjectURL(file)
		setFilePreview(filePreview)
		onChange(file, name, scope)
	}

	function handleAutocompleteLoaded(autocomplete) {
		setAutocomplete(autocomplete)
	}

	function handlePlaceChanged() {
		const {onChange, name, value} = props,
			place = autoComplete.getPlace()

		// Reset the address because we're about to overwrite with info from Google
		const addressFound = {
			...value,
			latitude: null,
			longitude: null,
			street: '',
			street_2: '',
			city: '',
			state: '',
			postal_code: '',
			region: '',
			country_code: 'GB'
		}

		const postalCodes = {
			AB: 'Scotland: North',
			AL: 'East of England',
			B: 'West Midlands',
			BA: 'South West',
			BB: 'North West',
			BD: 'North West',
			BH: 'South West',
			BL: 'North West',
			BN: 'South East',
			BR: 'Greater London',
			BS: 'South West',
			BT: 'Northern Ireland',
			CA: 'North West',
			CB: 'East of England',
			CF: 'Wales: South',
			CH: 'North West',
			CM: 'East of England',
			CO: 'East of England',
			CR: 'Greater London',
			CT: 'South East',
			CV: 'West Midlands',
			CW: 'North West',
			DA: 'Greater London',
			DD: 'Scotland: South',
			DE: 'East Midlands',
			DG: 'Scotland: South',
			DH: 'North East',
			DL: 'North East',
			DN: 'East Midlands',
			DT: 'South West',
			DY: 'West Midlands',
			E: 'Greater London',
			EC: 'Greater London',
			EH: 'Scotland: South',
			EN: 'Greater London',
			EX: 'South West',
			FK: 'Scotland: South',
			FY: 'North West',
			G: 'Scotland: South',
			GL: 'South West',
			GU: 'South East',
			GY: 'Channel Islands',
			HA: 'Greater London',
			HD: 'North West',
			HG: 'North East',
			HP: 'East of England',
			HR: 'West Midlands',
			HS: 'Scotland: North',
			HU: 'North East',
			HX: 'North West',
			IG: 'Greater London',
			IM: 'Isle of Man',
			IP: 'East of England',
			IV: 'Scotland: North',
			JE: 'Channel Islands',
			KA: 'Scotland: South',
			KT: 'Greater London',
			KW: 'Scotland: North',
			KY: 'Scotland: South',
			L: 'North West',
			LA: 'North West',
			LD: 'Wales: South',
			LE: 'East Midlands',
			LL: 'Wales: North',
			LN: 'East Midlands',
			LS: 'North East',
			LU: 'East of England',
			M: 'North West',
			ME: 'South East',
			MK: 'South East',
			ML: 'Scotland: South',
			N: 'Greater London',
			NE: 'North East',
			NG: 'East Midlands',
			NN: 'West Midlands',
			NP: 'Wales: South',
			NR: 'East of England',
			NW: 'Greater London',
			OL: 'North West',
			OX: 'South East',
			PA: 'Scotland: South',
			PE: 'East of England',
			PH: 'Scotland: South',
			PL: 'South West',
			PO: 'South East',
			PR: 'North West',
			QC: 'Non-geographic',
			RG: 'South East',
			RH: 'South East',
			RM: 'Greater London',
			S: 'East Midlands',
			SA: 'Wales: South',
			SE: 'Greater London',
			SG: 'East of England',
			SK: 'North West',
			SL: 'South East',
			SM: 'Greater London',
			SN: 'South West',
			SO: 'South East',
			SP: 'South West',
			SR: 'North East',
			SS: 'East of England',
			ST: 'West Midlands',
			SW: 'Greater London',
			SY: 'Wales: North',
			TA: 'South West',
			TD: 'Scotland: South',
			TF: 'West Midlands',
			TN: 'South East',
			TQ: 'South West',
			TR: 'South West',
			TS: 'North East',
			TW: 'Greater London',
			UB: 'Greater London',
			W: 'Greater London',
			WA: 'North West',
			WC: 'Greater London',
			WD: 'Greater London',
			WF: 'North East',
			WN: 'North West',
			WR: 'West Midlands',
			WS: 'West Midlands',
			WV: 'West Midlands',
			YO: 'North East',
			ZE: 'Scotland: North'
		}

		addressFound.latitude = place?.geometry?.location.lat()
		addressFound.longitude = place?.geometry?.location.lng()

		if (place.address_components) {
			place.address_components.forEach((component) => {
				if (component.types.includes("street_number")) {
					addressFound.street = component.long_name
				} else if(component.types.includes("route")) {
					addressFound.street += ' ' + component.long_name
				} else if (component.types.includes("sublocality")) {
					addressFound.street_2 = component.long_name
				} else if(component.types.includes("locality") || component.types.includes("postal_town")) {
					addressFound.city = component.long_name
				} else if(component.types.includes("administrative_area_level_2")) {
					addressFound.state = component.short_name
				} else if(component.types.includes("postal_code")) {
					addressFound.postal_code = component.long_name

					const postcodeKey1 = component.long_name.charAt(0).toUpperCase()
					const postcodeKey2 = component.long_name.substring(0, 2).toUpperCase()
					if (postcodeKey2 in postalCodes) {
						addressFound.region = postalCodes[postcodeKey2]
					}
					else if (postcodeKey1 in postalCodes) {
						addressFound.region = postalCodes[postcodeKey1]
					}
				}
			})

			setAddressFound(addressFound)
		}

		onChange(addressFound, name)
	}

	function handleTinymceChange(editorValue) {
		const {onChange, name, scope} = props
		onChange(editorValue, name, scope)
	}

	function handleTinymceImageUpload(blobInfo, success, failure) {
		const formData = new FormData()
		formData.append('file', blobInfo.blob(), blobInfo.filename());

		const broadcastsService = new BroadcastsService()
		return broadcastsService.imageUpload(formData)
			.then((response) => {
				if (response.success) {
					return success(response.data.location)
				}
				else {
					return failure('Invalid JSON: ' + response.data.location)
				}
			}).catch((error) => {
				return failure('Error: ' + error)
			})
	}

	function renderError() {
		const { error } = props

		return error && (typeof error !== "object" || Array.isArray(error)) && createErrorElement(error)
	}

	function createErrorElement(error) {
		return <label className="input-error" dangerouslySetInnerHTML={{__html: error}}></label>
	}

	function renderEditToggle() {
		const { editToggle, editBlock } = props

		return <>
			{(editToggle && !editBlock && !editing) &&
				<div className="profile-controls">
					<button type="button" onClick={enableEditing.bind(this)}>
						<FontAwesomeIcon icon={faEdit} title="Edit" />
						<span className="sr-only"> Edit</span>
					</button>
				</div>
			}

			{(editToggle && editing) &&
				<div className="profile-controls">
					<button type="button" onClick={disableEditing.bind(this)}>
						<FontAwesomeIcon icon={faRedo} title="redo"/>
						<span className="sr-only"> Cancel</span>
					</button>

					{props.onSave &&
						<button type="button" onClick={handleSave.bind(this)}>
							<FontAwesomeIcon icon={faCheckCircle} title="save"/>
							<span className="sr-only"> Save</span>
						</button>
					}
				</div>
			}
		</>
	}

	function enableEditing() {
		setEditing(true)
	}

	function disableEditing() {
		setEditing(false)

		const { name, scope, onChange, initialValue } = props
		onChange(initialValue, name, scope)
	}

	function handleSave() {
		const { onSave, name } = props

		onSave(name)
		const { error } = props

		if(!error) {
			setEditing(false)
		}
	}

	return <div className={classes}>
		{renderInput()}
		{renderError()}
		{renderEditToggle()}
	</div>
}