
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class BroadcastsService {
	async getTemplateOptions() {
		return await apiService.get('broadcasts/templates/options')
	}

	async getRecipientOptions(search, type) {
		const queryParams = {
			search: search,
			type: type
		}

		return await apiService.get('broadcasts/recipients/options', {}, queryParams)
	}

	async schedule(itemId, formData) {
		return await apiService.put(`broadcasts/${itemId}/schedule`, {body: formData})
	}

	async unschedule(itemId) {
		return await apiService.put(`broadcasts/${itemId}/unschedule`)
	}

	async preview(itemId) {
		return await apiService.get(`broadcasts/${itemId}/preview`)
	}

	async send(itemId) {
		return await apiService.put(`broadcasts/${itemId}/send`)
	}

	async delete(itemId) {
		return await apiService.delete(`broadcasts/${itemId}`)
	}

	async imageUpload(formData) {
		return await apiService.post(`broadcasts/image/upload`, {body: formData}, true)
	}
}
